// import function to register Swiper custom elements
import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
// register Swiper custom elements
// register();
document.addEventListener("DOMContentLoaded", () => {
  const hero = new Swiper(".swiper-hero", {
    modules: [Autoplay],
    loop: true,
    autoplay: {
      delay: 4000,
    },
  });

  const nextEl = document.querySelector(".button-next");
  const prevEl = document.querySelector(".button-prev");
  const slider = new Swiper(".testimonials", {
    modules: [Autoplay, Pagination],
    centeredSlides: true,
    centeredSlidesBounds: true,
    loop: true,
    navigation: {
      nextEl,
      prevEl,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 4000,
    },
    speed: 600,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  });
});
